/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 120px 0 60px;
  display: flex;
  align-items: center;
}

@media (min-width: 1200px) {
  .hero:after {
    position: absolute;
    content: '';
    width: 44%;
    background-image: linear-gradient(180deg, color-mix(in srgb, var(--background-color), transparent 15%), color-mix(in srgb, var(--background-color), transparent 15%) 100%),
      linear-gradient(180deg, rgb(0, 0, 0), rgb(0, 0, 0) 100%);
    top: 0;
    bottom: 0;
    z-index: 2;
  }
}

.hero:before {
  position: absolute;
  content: '';
  background: color-mix(in srgb, var(--background-color), transparent 40%);
  inset: 0;
  z-index: 2;
}

.hero .bgimage {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hero .container,
.hero .container-fluid {
  z-index: 3;
  position: relative;
}

.hero h1 {
  margin: 0;
  font-size: 3rem;
  font-weight: 700;
}

@keyframes pulse {
  0% {
    /* box-shadow: 0 0 0 0 rgb(224, 26, 79, 0.7); */
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.btn {
  position: relative;
  cursor: pointer;
  font-weight: 700;
  transition: all 0.2s;
  padding: 10px 20px;
  border-radius: 100px;
  background: var(--accent-color);
  color: #fff;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.btn:nth-child(1) {
  animation: pulse 2s infinite;
  background-color: #e01a4f;
}

.btn:hover {
  transform: scale(1.02);
}

.btn > i {
  font-size: 19px;
  width: 34px;
  transition: transform 0.3s ease-in-out;
}

.btn:active {
  transform: scale(0.95);
}

@keyframes clickMove {
  0% {
    transform: translateY(25px);
  }

  100% {
    transform: translateY(0px);
  }
}

.clickIcon {
  position: absolute;
  bottom: -30px;
  right: -5px;
  width: 60px;
  height: 60px;
  z-index: 200;
  animation: 3s clickMove infinite;
}

.form label {
  color: #171717;
}

.form-wrapper {
  display: flex;
}

@media (max-width: 1200px) {
  .form-wrapper {
    display: none;
  }
}

.bt {
  border: none;
  user-select: none;
  font-size: 18px;
  color: white;
  text-align: center;
  background-color: var(--accent-color);
  border-radius: 10px;
  height: 60px;
  line-height: 60px;
  width: 155px;
  transition: all 0.2s ease;
  position: relative;
}

.msg {
  height: 0;
  width: 0;
  border-radius: 2px;
  position: absolute;
  left: 15%;
  top: 25%;
}

.bt:active {
  transition: all 0.001s ease;
  background-color: #5d9fcd;
  box-shadow: #97989a 0 0 0 0;
  transform: translateX(1px) translateY(1px);
}

.bt:hover .msg {
  animation: msgRun 2s forwards;
}

@keyframes msgRun {
  0% {
    border-top: #d6d6d9 0 solid;
    border-bottom: #f2f2f5 0 solid;
    border-left: #f2f2f5 0 solid;
    border-right: #f2f2f5 0 solid;
  }

  20% {
    border-top: #d6d6d9 14px solid;
    border-bottom: #f2f2f5 14px solid;
    border-left: #f2f2f5 20px solid;
    border-right: #f2f2f5 20px solid;
  }

  25% {
    border-top: #d6d6d9 12px solid;
    border-bottom: #f2f2f5 12px solid;
    border-left: #f2f2f5 18px solid;
    border-right: #f2f2f5 18px solid;
  }

  80% {
    border-top: transparent 12px solid;
    border-bottom: transparent 12px solid;
    border-left: transparent 18px solid;
    border-right: transparent 18px solid;
  }

  100% {
    transform: translateX(150px);
    border-top: transparent 12px solid;
    border-bottom: transparent 12px solid;
    border-left: transparent 18px solid;
    border-right: transparent 18px solid;
  }
}

.success {
  width: 97%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  padding: 20px 20px;
  margin: auto;
  border-radius: 10px;
  color: #fff;
  margin-top: 30px;
}

.error {
  width: 97%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  padding: 20px 20px;
  margin: auto;
  border-radius: 10px;
  color: #fff;
  margin-top: 30px;
}

.alert {
  width: 97%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffc100;
  padding: 20px 20px;
  margin: auto;
  border-radius: 10px;
  color: black;
  margin-top: 30px;
}
